import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Modal, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import "../../Assets/Styles/Home.css";

const Section5 = () => {
  const [Exhibition, setExhibition] = useState([]);
  const [visible, setVisible] = useState(7);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    axios
      .get("/exhibition/get")
      .then((response) => {
        setExhibition(response.data.responseData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const showMoreItems = () => {
    setVisible((prevVisible) => Math.min(prevVisible + 7, Exhibition.length));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const cardRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("card-visible");
        }
      });
    }, observerOptions);

    cardRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      cardRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [Exhibition]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <Container fluid>
      <Row>
        <h1 className="text-secondary-emphasis textheading fw-bolder pt-5 px-5">
          Exhibition
        </h1>
        {Exhibition.slice(0, visible).map((item, index) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={4}
            xxl={4}
            className="pt-3"
            key={item.id}
          >
            <Card
              ref={(el) => (cardRefs.current[index] = el)}
              className="rounded-5 h-100 cardshadow card-hidden"
            >
              <Card.Img
                variant="top"
                src={item.img}
                alt={item.name || "Exhibition Image"}
                className="px-3 pt-3"
                onClick={() => handleImageClick(item.img)}
              />
              <Card.Body>
                <Card.Title className="fw-bolder text-uppercase">
                  {item.title}
                </Card.Title>
                <Card.Text dangerouslySetInnerHTML={{ __html: truncateText(item.desc, 20) }} />
              </Card.Body>
              <div className="d-flex justify-content-end pb-3 pe-4">
                <Link
                  to={`/exhibition/${item.id}`}
                  state={{ exhibitionItem: item }}
                >
                  <Button className="rounded-5 border-3 border-0 px-3 py-2 learn_more">
                    Learn more
                  </Button>
                </Link>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      {Exhibition.length > visible && (
        <div className="d-flex justify-content-center pt-5">
          <Button
            onClick={showMoreItems}
            className="rounded-5 border-3 border-0 px-4 py-2 learn_more"
          >
            Load more
          </Button>
        </div>
      )}

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body className="position-relative p-0 m-0">
          <button
            type="button"
            className="position-absolute top-0 end-0 m-2"
            onClick={handleClose}
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontSize: "1.5rem",
              color: "#000",
            }}
          >
            <FaTimes />
          </button>
          {selectedImage && (
            <img
              src={selectedImage}
              className="img-fluid w-100"
              alt="Exhibition"
            />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Section5;
