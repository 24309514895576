import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  // Scroll State
  const [isVisible, setIsVisible] = useState(false);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const listenToScroll = () => {
    let heightToHidden = 250;
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    windowScroll > heightToHidden ? setIsVisible(true) : setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  });
  return (
    <>
      {/* Sroll To Top */}
      {isVisible && (
        <div className="scroll_top" onClick={scrollTop}>
          <i class="bi bi-chevron-up"></i>
        </div>
      )}
      <Container fluid className="footer">
        <Row>
          <Col className="p-0 ">
            <Card body style={{ backgroundColor: "#05313E" }} className="p-0 m-0">
              <span className="d-flex justify-content-center text-white text-center flex-column flex-md-row">
                <span>
                  Copyright &copy; {new Date().getFullYear()} | Bilz Designed by Sumago with
                </span>
                <Link
                  to="https://sumagoinfotech.com/"
                  style={{ textDecoration: 'none', color: '#fff' }}
                  className="d-block d-md-inline">
                  ❤️ From Nashik
                </Link>
              </span>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;