import React from 'react'
import "../Assets/Styles/EnquirySheet.css"
import { Container } from 'react-bootstrap';

const EnquirySheet = () => {
  return (
    <>
        <Container>
            {/* <Row>
                <Col> */}
                    <a class="buttonLink" href="#"><button type="button" class="btn btn-large Enquiry Sheet">Enquiry Sheet</button></a>
                {/* </Col>
            </Row> */}
        </Container>
        
    </>
  )
}

export default EnquirySheet