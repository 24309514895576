import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FaTimes } from "react-icons/fa";
import '../Assets/Styles/Product.css';

const Product = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem('categoryid');
  const [products, setProducts] = useState([]);
  const [productImages, setProductImages] = useState(null);
  const [productCard, setProductCard] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [visibleCards, setVisibleCards] = useState(new Set()); // State for visible cards
  const cardRefs = useRef([]); // Ref to store card references

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get('/productName/get');
      setProducts(response.data.responseData);
    } catch (error) {
      console.error('Error fetching all products:', error);
    }
  };

  const fetchProductImages = async (id) => {
    try {
      const response = await axios.get(`/productName/get/${id}`);
      setProductImages(response.data);
    } catch (error) {
      console.error('Error fetching product images:', error);
    }
  };

  const fetchProductDetails = async (id) => {
    try {
      const response = await axios.get(`/productName/getdetails/${id}`);
      setProductCard(response.data);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setProductCard([]);
    }
  };

  useEffect(() => {
    fetchAllProducts();
    fetchProductImages(id);
    fetchProductDetails(id);
  }, [id]);

  // Intersection Observer to track visibility
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // Trigger when 10% of the card is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisibleCards(prev => new Set(prev).add(entry.target.id));
          observer.unobserve(entry.target); // Stop observing after it's visible
        }
      });
    }, options);

    // Observe each card
    cardRefs.current.forEach(card => {
      if (card) observer.observe(card);
    });

    return () => {
      // Cleanup observer on unmount
      observer.disconnect();
    };
  }, [productCard]);

  const truncateText = (htmlString, wordLimit) => {
    const text = htmlString.replace(/<[^>]+>/g, ' ');
    const words = text.split(' ').filter(word => word.trim() !== '');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <>
      {/* Product Image Section */}
      {productImages ? (
        <img src={`https://api.antivibrations.com/${productImages.img}`} alt="Product Image" style={{ marginTop: "60px" }} className="img-fluid w-100" />
      ) : (
        <p>No data available for product image.</p>
      )}

      {/* Product Cards Section */}
      <Container fluid className='ProductsCard pt-4'>
        {productCard.length > 0 ? (
          <Row>
            {productCard.map((member, index) => (
              <Col xs={12} sm={6} md={6} lg={4} key={index} className='pt-1 pb-5 pe-3'>
                <Card 
                  id={`card-${index}`} 
                  ref={el => cardRefs.current[index] = el} 
                  className={`rounded rounded-5 cardshadow ${visibleCards.has(`card-${index}`) ? 'animate' : ''}`} 
                  style={{ height: '450px' }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://api.antivibrations.com/${member.img}`}
                    alt={member.title}
                    className='pt-3 px-3 rounded-5'
                    style={{ height: '200px', objectFit: 'cover', cursor: 'pointer' }} 
                    onClick={() => handleImageClick(`https://api.antivibrations.com/${member.img}`)}
                  />
                  <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Card.Title className='fw-bold text-uppercase'>
                      {truncateText(member.title, 5)}
                    </Card.Title>
                    <Card.Text className='text-justify text-dark 1h-sm'
                      dangerouslySetInnerHTML={{ __html: truncateText(member.description, 20) }}
                    />
                  </Card.Body>
                  <div className="d-flex justify-content-end pb-3 pe-4">
                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more" onClick={() => { navigate(`/ProductDetail/${member.slug}`); localStorage.setItem('subproductid', member.id) }}>
                      Learn more
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <p>No data available for product details.</p>
        )}
      </Container>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body className="position-relative p-0 m-0">
          <button
            type="button"
            className="position-absolute top-0 end-0 m-2"
            onClick={handleClose}
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontSize: "1.5rem",
              color: "#000",
            }}
          >
            <FaTimes />
          </button>
          {selectedImage && (
            <img
              src={selectedImage}
              className="img-fluid w-100"
              alt="Exhibition"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Product;
