import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import '../../Assets/Styles/ContactUs.css'
import Distributors from '../../Assets/Images/ContactUs/Distributors.png'
import Contact from '../../Assets/Images/ContactUs/Contact.png'
import Feedbackicon from '../../Assets/Images/ContactUs/Feedback icon.png'
import Enquiry from '../../Assets/Images/ContactUs/Enquiry.png'
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';


const Section1 = () => {

    const [showModal, setShowModal] = useState({
        distributors: false,
        contact: false,
        feedback: false,
        enquirysheet: false,
    });

    const handleShow = (form) => {
        setShowModal({ ...showModal, [form]: true });
    };

    const handleClose = (form) => {
        setShowModal({ ...showModal, [form]: false });
    };

    // Form States
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company_name, setCompanyName] = useState("");
    const [msg, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);

    // const handleInputChange = (setter) => (e) => {
    //     setter(e.target.value);
    //     // Clear the error when the user types valid data
    //     if (e.target.value.trim()) {
    //         setErrors((prev) => ({ ...prev, [e.target.name]: undefined }));
    //     }
    // };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
        if (errors.msg && event.target.name === "msg") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                msg: '',
            }));
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        if (value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                captcha: '',
            }));
        }
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Name is Required";
            isValid = false;
        }
        if (!phone.trim()) {
            errors.phone = "Mobile No. is Required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phone)) {
            errors.phone = "Mobile number must be exactly 10 digits";
            isValid = false;
        }
        if (!email.trim()) {
            errors.email = "Email Id is Required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid Email Id address";
            isValid = false;
        }
        if (!company_name.trim()) {
            errors.company_name = "Company Name is Required";
            isValid = false;
        }
        if (!msg.trim()) {
            errors.msg = "Message is Required";
            isValid = false;
        }
        if (!captchaValue) {
            errors.captcha = 'Please complete the CAPTCHA';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post("distributer/add", {
                    name,
                    email,
                    phone,
                    company_name,
                    msg,
                });

                if (!captchaValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: 'Please complete the CAPTCHA',
                    }));
                } else {
                    // Handle form submission
                    console.log('Form submitted:', { msg });

                    // Clear CAPTCHA and reset state after submission
                    recaptchaRef.current.reset();
                    setCaptchaValue('');
                    setMessage('');
                    setErrors({});
                }

                if (response.status === 200) {
                    // Reset form fields and state after successful submission
                    setName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                    setErrors({});
                    setCaptchaValue(null); // Clear captcha value after successful submission
                    alert("Thank you, we will connect with you soon");
                }
            } catch (error) {
                console.error("Error submitting data:", error);
                const newErrors = { ...errors };

                // Check if the error is a validation error for mobile number or email
                if (error.response?.data?.message === "Validation error: Mobile number already exists.") {
                    newErrors.phone = "Phone number already exists.";
                } else if (error.response?.data?.message === "Validation error: Email already exists.") {
                    newErrors.email = "Email already exists.";
                } else {
                    newErrors.general = "Failed to submit data. Please try again later.";
                }

                setErrors(newErrors);
            }
        }
    };

    // contact 
    const validateForm1 = () => {
        let errors = {};
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Name is Required";
            isValid = false;
        }
        if (!phone.trim()) {
            errors.phone = "Mobile No. is Required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phone)) {
            errors.phone = "Mobile number must be exactly 10 digits";
            isValid = false;
        }
        if (!email.trim()) {
            errors.email = "Email Id is Required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid Email Id address";
            isValid = false;
        }
        if (!company_name.trim()) {
            errors.company_name = "Company Name is Required";
            isValid = false;
        }
        if (!msg.trim()) {
            errors.msg = "Message is Required";
            isValid = false;
        }
        if (!captchaValue) {
            errors.captcha = 'Please complete the CAPTCHA';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        if (validateForm1()) {
            try {
                const response = await axios.post("contact_form/add", {
                    name,
                    email,
                    phone,
                    company_name,
                    msg,
                });

                if (!captchaValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: 'Please complete the CAPTCHA',
                    }));
                } else {
                    // Handle form submission
                    console.log('Form submitted:', { msg });

                    // Clear CAPTCHA and reset state after submission
                    recaptchaRef.current.reset();
                    setCaptchaValue('');
                    setMessage('');
                    setErrors({});
                }

                if (response.status === 200) {
                    // Reset form fields and state after successful submission
                    setName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                    setErrors({});
                    setCaptchaValue(null); // Clear captcha value after successful submission
                    alert("Thank you, we will connect with you soon");
                }
            } catch (error) {
                console.error("Error submitting data:", error);
                const newErrors = { ...errors };

                // Check if the error is a validation error for mobile number or email
                if (error.response?.data?.message === "Validation error: Mobile number already exists.") {
                    newErrors.phone = "Phone number already exists.";
                } else if (error.response?.data?.message === "Validation error: Email already exists.") {
                    newErrors.email = "Email already exists.";
                } else {
                    newErrors.general = "Failed to submit data. Please try again later.";
                }

                setErrors(newErrors);
            }
        }
    };

    // feedback 
    const validateForm2 = () => {
        let errors = {};
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Name is Required";
            isValid = false;
        }
        if (!phone.trim()) {
            errors.phone = "Mobile No. is Required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phone)) {
            errors.phone = "Mobile number must be exactly 10 digits";
            isValid = false;
        }
        if (!email.trim()) {
            errors.email = "Email Id is Required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid Email Id address";
            isValid = false;
        }
        if (!company_name.trim()) {
            errors.company_name = "Company Name is Required";
            isValid = false;
        }
        if (!msg.trim()) {
            errors.msg = "Message is Required";
            isValid = false;
        }
        if (!captchaValue) {
            errors.captcha = 'Please complete the CAPTCHA';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        if (validateForm2()) {
            try {
                const response = await axios.post("feedback/add", {
                    name,
                    email,
                    phone,
                    company_name,
                    msg,
                });

                if (!captchaValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: 'Please complete the CAPTCHA',
                    }));
                } else {
                    // Handle form submission
                    console.log('Form submitted:', { msg });

                    // Clear CAPTCHA and reset state after submission
                    recaptchaRef.current.reset();
                    setCaptchaValue('');
                    setMessage('');
                    setErrors({});
                }

                if (response.status === 200) {
                    // Reset form fields and state after successful submission
                    setName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                    setErrors({});
                    setCaptchaValue(null); // Clear captcha value after successful submission
                    alert("Thank you, we will connect with you soon");
                }
            } catch (error) {
                console.error("Error submitting data:", error);
                const newErrors = { ...errors };

                // Check if the error is a validation error for mobile number or email
                if (error.response?.data?.message === "Validation error: Mobile number already exists.") {
                    newErrors.phone = "Phone number already exists.";
                } else if (error.response?.data?.message === "Validation error: Email already exists.") {
                    newErrors.email = "Email already exists.";
                } else {
                    newErrors.general = "Failed to submit data. Please try again later.";
                }

                setErrors(newErrors);
            }
        }
    };

    // enquirysheet 
    const [company, setCompany] = useState("");
    const [designation, setDesignation] = useState("");
    const [business, setBusiness] = useState("");
    const [phoneno, setPhones] = useState("");
    const [date, setDate] = useState("");
    const [contact_person, setContactPerson] = useState("");
    const [email_id, setEmailId] = useState("");
    const [machine_type, setMachineType] = useState("");
    const [machine_manufacturer, setMachineManufacturer] = useState("");
    const [machine_weight, setMachineWeight] = useState("");
    const [machine_tool_weight, setMachineToolWeight] = useState("");
    const [mounting_position, setMountingPosition] = useState("");
    const [mounting_hole_diameter, setMountingHoleDiameter] = useState("");
    const [mounting_foot, setMountingFoot] = useState("");
    const [speed_of_machine, setSpeedOfMachine] = useState("");
    const [machine_stokes, setMachineStokes] = useState("");
    const [machine_feed_rate, setMachineFeedRate] = useState("");
    const [current_location, setCurrentlocation] = useState("");

    const validateForm3 = () => {
        let errors = {};
        let isValid = true;

        if (!company.trim()) {
            errors.company = "Company is Required";
            isValid = false;
        }
        if (!designation.trim()) {
            errors.designation = "Designation is Required";
            isValid = false;
        }
        if (!business.trim()) {
            errors.business = "Business is Required";
            isValid = false;
        }
        if (!phoneno.trim()) {
            errors.phoneno = "Phone No. is Required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phoneno)) {
            errors.phoneno = "Phone number must be exactly 10 digits";
            isValid = false;
        }
        if (!date.trim()) {
            errors.date = "Date is Required";
            isValid = false;
        }
        if (!contact_person.trim()) {
            errors.contact_person = "Contact Person is Required";
            isValid = false;
        }
        if (!email_id.trim()) {
            errors.email_id = "Email Id is Required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email_id)) {
            errors.email_id = "Invalid Email Id address";
            isValid = false;
        }
        if (!machine_type.trim()) {
            errors.machine_type = "Machine Type is Required";
            isValid = false;
        }
        if (!machine_manufacturer.trim()) {
            errors.machine_manufacturer = "Machine Manufacturer is Required";
            isValid = false;
        }
        if (!machine_weight.trim()) {
            errors.machine_weight = "Machine Weight is Required";
            isValid = false;
        }
        if (!machine_tool_weight.trim()) {
            errors.machine_tool_weight = "Machine Tool Weight is Required";
            isValid = false;
        }
        if (!mounting_position.trim()) {
            errors.mounting_position = "Mounting Position is Required";
            isValid = false;
        }
        if (!mounting_hole_diameter.trim()) {
            errors.mounting_hole_diameter = "Mounting Hole Diameter is Required";
            isValid = false;
        }
        if (!mounting_foot.trim()) {
            errors.mounting_foot = "Mounting Foot is Required";
            isValid = false;
        }
        if (!speed_of_machine.trim()) {
            errors.speed_of_machine = "Speed Of Machine is Required";
            isValid = false;
        }
        if (!machine_stokes.trim()) {
            errors.machine_stokes = "Machine Stokes is Required";
            isValid = false;
        }
        if (!machine_feed_rate.trim()) {
            errors.machine_feed_rate = "Machine Feed Rate is Required";
            isValid = false;
        }
        if (!current_location.trim()) {
            errors.current_location = "Current Location is Required";
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleSubmit3 = async (e) => {
        e.preventDefault();
        if (validateForm3()) {
            try {
                const response = await axios.post("EnquirySheet/add", {
                    company, designation, business, phoneno, date, contact_person, email_id, machine_type, machine_manufacturer, machine_weight, 
      machine_tool_weight, mounting_position, mounting_hole_diameter, mounting_foot, speed_of_machine, machine_stokes, machine_feed_rate, current_location,
                });

                if (!captchaValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: 'Please complete the CAPTCHA',
                    }));
                } else {
                    // Handle form submission
                    console.log('Form submitted:', { msg });

                    // Clear CAPTCHA and reset state after submission
                    recaptchaRef.current.reset();
                    setCaptchaValue('');
                    setMessage('');
                    setErrors({});
                }

                if (response.status === 200) {
                    // Reset form fields and state after successful submission
                    setCompany("");
                    setDesignation("");
                    setBusiness("");
                    setPhones("");
                    setDate("");
                    setContactPerson("");
                    setEmailId("");
                    setMachineType("");
                    setMachineManufacturer("");
                    setMachineWeight("");
                    setMachineToolWeight("");
                    setMountingPosition("");
                    setMountingHoleDiameter("");
                    setMountingFoot("");
                    setSpeedOfMachine("");
                    setMachineStokes("");
                    setMachineFeedRate("");
                    setCurrentlocation("");
                    setMessage("");
                    setErrors({});
                    setCaptchaValue(null); // Clear captcha value after successful submission
                    alert("Thank you, we will connect with you soon");
                }
            } catch (error) {
                console.error("Error submitting data:", error);
                const newErrors = { ...errors };

                // Check if the error is a validation error for mobile number or email
                if (error.response?.data?.message === "Validation error: Mobile number already exists.") {
                    newErrors.phone = "Phone number already exists.";
                } else if (error.response?.data?.message === "Validation error: Email already exists.") {
                    newErrors.email = "Email already exists.";
                } else {
                    newErrors.general = "Failed to submit data. Please try again later.";
                }

                setErrors(newErrors);
            }
        }
        
    };




    // =========================
    const [selectedState, setSelectedState] = useState('');
    const [statesdata, setStatesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [states, setStates] = useState([]);

    const fetchAllStates = async () => {
        try {
            const response = await axios.get('/stateinfo/get');
            setStatesData(response.data.responseData);

            // Update states with unique state names from the response
            const uniqueStateNames = [...new Set(response.data.responseData.map(state => state.statename))];
            setStates(uniqueStateNames);

            // Set the first state as the default selected state
            if (uniqueStateNames.length > 0) {
                setSelectedState(uniqueStateNames[0]);
                const filtered = response.data.responseData.filter(item => item.statename === uniqueStateNames[0]);
                setFilteredData(filtered);
            }
        } catch (error) {
            console.error('Error fetching all states:', error);
        }
    };


    useEffect(() => {
        fetchAllStates();
    }, []);

    const handleStateChange = (e) => {
        const selected = e.target.value;
        setSelectedState(selected);

        // Filter statesdata based on selected state
        const filtered = statesdata.filter((item) => item.statename === selected);
        setFilteredData(filtered);
    };
    return (
        <>
            <Container fluid className='Section1BackgroundImg'>
                <Row className='mx-lg-2 py-5'>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} className='py-4'>
                        <Card className='rounded-5 h-100 px-2 distributorcard cardshadow'>
                            <Card.Body className='py-5  '>
                                <h1 className='fw-bolder'>Find Your <span className='highlight'>Distributor</span></h1>
                                <p className='fw-bolder px-3'>Distributor India</p>
                                <Row>
                                    <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} >
                                        <div className='mob-dist dist' style={{ }}>
                                            <Form.Group controlId="stateSelect" className="mb-3">
                                                <Form.Control
                                                    as="select"
                                                    value={selectedState}
                                                    onChange={handleStateChange}
                                                    className="form-select"
                                                >
                                                    <option value="" disabled>Select a state</option>
                                                    {states.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={7} lg={7} xl={7} xxl={7}>
                                        <div style={{ maxHeight: "260px", overflowY: "auto", borderRadius: "5px", padding: "0px" }}>
                                            {filteredData.map((record) => (
                                                <div key={record.id} style={{ margin: "0", padding: "0px", borderBottom: "1px solid #ddd" }}>
                                                    <h3>{record.company_name}</h3>
                                                    <p><strong>Contact Person Name:</strong> {record.contact_person_name}</p>
                                                    <p><strong>Phone:</strong> {record.phone}</p>
                                                    <p><strong>Location:</strong> {record.location}</p>
                                                    <p><strong>Address:</strong> {record.address}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className='d-flex justify-content-center my-5'>
                        <Card className='distributorcard1' style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ul className='my-5 pe-4 pt-2'>
                                <li className='list-group-item border-0 fw-bolder' style={{ cursor: 'pointer' }} onClick={() => handleShow('distributors')}>
                                    <img
                                        src={Distributors}
                                        className='img-fluid'
                                        alt='Distributors'
                                        style={{ cursor: 'pointer' }}
                                    /> Distributors Form
                                </li>
                                <li className='list-group-item border-0 fw-bolder' style={{ cursor: 'pointer' }} onClick={() => handleShow('contact')}>
                                    <img
                                        src={Contact}
                                        className='img-fluid'
                                        alt='Contact'
                                        style={{ cursor: 'pointer' }}
                                    /> Contact Form
                                </li>
                                <li className='list-group-item border-0 fw-bolder' style={{ cursor: 'pointer' }} onClick={() => handleShow('feedback')}>
                                    <img
                                        src={Feedbackicon}
                                        className='img-fluid'
                                        alt='Feedback'
                                        style={{ cursor: 'pointer' }}
                                    /> Feedback Form
                                </li>
                                <li className='list-group-item border-0 fw-bolder' style={{ cursor: 'pointer' }} onClick={() => handleShow('enquirysheet')}>
                                    <img
                                        src={Enquiry}
                                        className='img-fluid'
                                        alt='Feedback'
                                        style={{ cursor: 'pointer' }}
                                    /> Enquiry Form
                                </li>
                            </ul>
                        </Card>


                        {/* Distributors Modal */}
                        <Modal show={showModal.distributors} onHide={() => handleClose('distributors')}>
                            <Modal.Header closeButton className='bg-info modalform'>
                                <Modal.Title className='text-white'>Distributors Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name" // Add name attribute
                                            className='rounded-4'
                                            value={name}
                                            onChange={handleInputChange(setName)} // Use the new input change handler
                                        />
                                        {errors.name && <span className="error ms-2 fw-light text-danger">{errors.name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email Id : </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email" // Add name attribute
                                            className='rounded-4'
                                            value={email}
                                            onChange={handleInputChange(setEmail)} // Use the new input change handler
                                        />
                                        {errors.email && <span className="error ms-2 fw-light text-danger">{errors.email}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Mobile No. : </Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="phone_no" // Add name attribute
                                            className='rounded-4'
                                            minLength={10}
                                            maxLength={10}
                                            value={phone}
                                            onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                            onChange={handleInputChange(setPhones)} // Use the new input change handler
                                        />
                                        {errors.phone && <span className="error ms-2 fw-light text-danger">{errors.phone}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="company_name">
                                        <Form.Label>Company Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name" // Add name attribute
                                            className='rounded-4'
                                            value={company_name}
                                            onChange={handleInputChange(setCompanyName)} // Use the new input change handler
                                        />
                                        {errors.company_name && <span className="error ms-2 fw-light text-danger">{errors.company_name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="msg">
                                        <Form.Label>Message:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="msg"
                                            className="rounded-4"
                                            value={msg}
                                            onChange={handleInputChange(setMessage)}
                                        />
                                        {errors.msg && <span className="error ms-2 fw-light text-danger">{errors.msg}</span>}
                                    </Form.Group>

                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH"
                                        onChange={handleCaptchaChange}
                                    />
                                    {errors.captcha && <span className="error ms-2 fw-light text-danger">{errors.captcha}</span>}

                                    <div className="text-center">
                                        <Button
                                            variant="success"
                                            type="submit"
                                            style={{ background: '#82B547', border: 'none' }}
                                            className="my-4"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>


                        {/* Contact Modal */}
                        <Modal show={showModal.contact} onHide={() => handleClose('contact')}>
                            <Modal.Header closeButton className='bg-info modalform'>
                                <Modal.Title className='text-white'>Contact Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit1}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name" // Add name attribute
                                            className='rounded-4'
                                            value={name}
                                            onChange={handleInputChange(setName)} // Use the new input change handler
                                        />
                                        {errors.name && <span className="error ms-2 fw-light text-danger">{errors.name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email Id : </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email" // Add name attribute
                                            className='rounded-4'
                                            value={email}
                                            onChange={handleInputChange(setEmail)} // Use the new input change handler
                                        />
                                        {errors.email && <span className="error ms-2 fw-light text-danger">{errors.email}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Mobile No. : </Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="phone" // Add name attribute
                                            className='rounded-4'
                                            value={phone}
                                            maxLength={10}
                                            minLength={10}
                                            onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                            onChange={handleInputChange(setPhone)} // Use the new input change handler
                                        />
                                        {errors.phone && <span className="error ms-2 fw-light text-danger">{errors.phone}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="company_name">
                                        <Form.Label>Company Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name" // Add name attribute
                                            className='rounded-4'
                                            value={company_name}
                                            onChange={handleInputChange(setCompanyName)} // Use the new input change handler
                                        />
                                        {errors.company_name && <span className="error ms-2 fw-light text-danger">{errors.company_name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="msg">
                                        <Form.Label>Message:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="msg"
                                            className="rounded-4"
                                            value={msg}
                                            onChange={handleInputChange(setMessage)}
                                        />
                                        {errors.msg && <span className="error ms-2 fw-light text-danger">{errors.msg}</span>}
                                    </Form.Group>

                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH"
                                        onChange={handleCaptchaChange}
                                    />
                                    {errors.captcha && <span className="error ms-2 fw-light text-danger">{errors.captcha}</span>}

                                    <div className="text-center">
                                        <Button
                                            variant="success"
                                            type="submit"
                                            style={{ background: '#82B547', border: 'none' }}
                                            className="my-4"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>


                        {/* Feedback Modal */}
                        <Modal show={showModal.feedback} onHide={() => handleClose('feedback')}>
                            <Modal.Header closeButton className='bg-info modalform'>
                                <Modal.Title className='text-white'>Feedback Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit2}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name" // Add name attribute
                                            className='rounded-4'
                                            value={name}
                                            onChange={handleInputChange(setName)} // Use the new input change handler
                                        />
                                        {errors.name && <span className="error ms-2 fw-light text-danger">{errors.name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email Id : </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email" // Add name attribute
                                            className='rounded-4'
                                            value={email}
                                            onChange={handleInputChange(setEmail)} // Use the new input change handler
                                        />
                                        {errors.email && <span className="error ms-2 fw-light text-danger">{errors.email}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Mobile No. : </Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="phone" // Add name attribute
                                            className='rounded-4'
                                            value={phone}
                                            maxLength={10}
                                            minLength={10}
                                            onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                            onChange={handleInputChange(setPhone)} // Use the new input change handler
                                        />
                                        {errors.phone && <span className="error ms-2 fw-light text-danger">{errors.phone}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="company_name">
                                        <Form.Label>Company Name : </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name" // Add name attribute
                                            className='rounded-4'
                                            value={company_name}
                                            onChange={handleInputChange(setCompanyName)} // Use the new input change handler
                                        />
                                        {errors.company_name && <span className="error ms-2 fw-light text-danger">{errors.company_name}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="msg">
                                        <Form.Label>Message:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="msg"
                                            className="rounded-4"
                                            value={msg}
                                            onChange={handleInputChange(setMessage)}
                                        />
                                        {errors.msg && <span className="error ms-2 fw-light text-danger">{errors.msg}</span>}
                                    </Form.Group>

                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH"
                                        onChange={handleCaptchaChange}
                                    />
                                    {errors.captcha && <span className="error ms-2 fw-light text-danger">{errors.captcha}</span>}

                                    <div className="text-center">
                                        <Button
                                            variant="success"
                                            type="submit"
                                            style={{ background: '#82B547', border: 'none' }}
                                            className="my-4"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        {/* Enquiry Sheet Modal */}
                        <Modal aria-labelledby="example-modal-sizes-title-lg" size="lg" show={showModal.enquirysheet} onHide={() => handleClose('enquirysheet')}>
                            <Modal.Header closeButton className='bg-info modalform'>
                                <Modal.Title id="example-modal-sizes-title-lg" className='text-white'>Enquiry Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit3}>
                                    <Row>
                                        <h4 className='text-center fw-bold'>CUSTOMER DETAILES</h4>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Company : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="company"
                                                    className='rounded-4'
                                                    value={company}
                                                    onChange={handleInputChange(setCompany)}
                                                />
                                                {errors.company && <span className="error ms-2 fw-light text-danger">{errors.company}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Designation : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="designation"
                                                    className='rounded-4'
                                                    value={designation}
                                                    onChange={handleInputChange(setDesignation)}
                                                />
                                                {errors.designation && <span className="error ms-2 fw-light text-danger">{errors.designation}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Business : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="business"
                                                    className='rounded-4'
                                                    value={business}
                                                    onChange={handleInputChange(setBusiness)}
                                                />
                                                {errors.business && <span className="error ms-2 fw-light text-danger">{errors.business}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Phone No. : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phone_no"
                                                    className='rounded-4'
                                                    minLength={10}
                                                    maxLength={10}
                                                    value={phoneno}
                                                    onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                                    onChange={handleInputChange(setPhones)}
                                                />
                                                {errors.phoneno && <span className="error ms-2 fw-light text-danger">{errors.phoneno}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Date : </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="date"
                                                    className='rounded-4'
                                                    minLength={10}
                                                    maxLength={10}
                                                    value={date}
                                                    onChange={handleInputChange(setDate)}
                                                />
                                                {errors.date && <span className="error ms-2 fw-light text-danger">{errors.date}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Contact Person : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="contact_person"
                                                    className='rounded-4'
                                                    value={contact_person}
                                                    onChange={handleInputChange(setContactPerson)}
                                                />
                                                {errors.contact_person && <span className="error ms-2 fw-light text-danger">{errors.contact_person}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Email Id : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email_id"
                                                    className='rounded-4'
                                                    value={email_id}
                                                    onChange={handleInputChange(setEmailId)}
                                                />
                                                {errors.email_id && <span className="error ms-2 fw-light text-danger">{errors.email_id}</span>}
                                            </Form.Group>
                                        </Col>
                                        <h4 className='text-center fw-bold'>MACHINE DETAILES</h4>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Machine Type : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_type"
                                                    className='rounded-4'
                                                    value={machine_type}
                                                    onChange={handleInputChange(setMachineType)}
                                                />
                                                {errors.machine_type && <span className="error ms-2 fw-light text-danger">{errors.machine_type}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Machine Manufacturer : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_manufacturer"
                                                    className='rounded-4'
                                                    value={machine_manufacturer}
                                                    onChange={handleInputChange(setMachineManufacturer)}
                                                />
                                                {errors.machine_manufacturer && <span className="error ms-2 fw-light text-danger">{errors.machine_manufacturer}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Machine Weight : (kg.)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_weight"
                                                    className='rounded-4'
                                                    value={machine_weight}
                                                    onChange={handleInputChange(setMachineWeight)}
                                                />
                                                {errors.machine_weight && <span className="error ms-2 fw-light text-danger">{errors.machine_weight}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Machine Tool Weight : (kg.) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_tool_weight"
                                                    className='rounded-4'
                                                    value={machine_tool_weight}
                                                    onChange={handleInputChange(setMachineToolWeight)}
                                                />
                                                {errors.machine_tool_weight && <span className="error ms-2 fw-light text-danger">{errors.machine_tool_weight}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>No Of Mounting Position : (Nos.) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mounting_position"
                                                    className='rounded-4'
                                                    value={mounting_position}
                                                    onChange={handleInputChange(setMountingPosition)}
                                                />
                                                {errors.mounting_position && <span className="error ms-2 fw-light text-danger">{errors.mounting_position}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Mounting Hole Diameter : (mm.) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mounting_hole_diameter"
                                                    className='rounded-4'
                                                    value={mounting_hole_diameter}
                                                    onChange={handleInputChange(setMountingHoleDiameter)}
                                                />
                                                {errors.mounting_hole_diameter && <span className="error ms-2 fw-light text-danger">{errors.mounting_hole_diameter}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Size Of Mounting Foot : (LXWmm) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mounting_foot"
                                                    className='rounded-4'
                                                    value={mounting_foot}
                                                    onChange={handleInputChange(setMountingFoot)}
                                                />
                                                {errors.mounting_foot && <span className="error ms-2 fw-light text-danger">{errors.mounting_foot}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Speed Of Machine : (RPM) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="speed_of_machine"
                                                    className='rounded-4'
                                                    value={speed_of_machine}
                                                    onChange={handleInputChange(setSpeedOfMachine)}
                                                />
                                                {errors.speed_of_machine && <span className="error ms-2 fw-light text-danger">{errors.speed_of_machine}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Machine Stokes : (SPM) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_stokes"
                                                    className='rounded-4'
                                                    value={machine_stokes}
                                                    onChange={handleInputChange(setMachineStokes)}
                                                />
                                                {errors.machine_stokes && <span className="error ms-2 fw-light text-danger">{errors.machine_stokes}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Machine Feed Rate : (M/min) </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="machine_feed_rate"
                                                    className='rounded-4'
                                                    value={machine_feed_rate}
                                                    onChange={handleInputChange(setMachineFeedRate)}
                                                />
                                                {errors.machine_feed_rate && <span className="error ms-2 fw-light text-danger">{errors.machine_feed_rate}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Current location (Ground Floor,Basement, 1st floor, etc.) : </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="current_location"
                                                    className='rounded-4'
                                                    value={current_location}
                                                    onChange={handleInputChange(setCurrentlocation)}
                                                />
                                                {errors.current_location && <span className="error ms-2 fw-light text-danger">{errors.current_location}</span>}
                                            </Form.Group>
                                        </Col>
                                        <div className="text-center">
                                            <Button
                                                variant="success"
                                                type="submit"
                                                style={{ background: '#82B547', border: 'none' }}
                                                className="my-4"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Section1
