import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const SolutionApplicationDetails = () => {
    const location = useLocation();
    const { id } = useParams();
    const [applicationItem, setApplicationItem] = useState(location.state?.applicationItem || null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!applicationItem) {
            axios.get(`/ServiceDetail/${id}`)
                .then((response) => {
                    setApplicationItem(response.data.responseData);
                })
                .catch((error) => {
                    console.error("There was an error fetching the data!", error);
                });
        }
    }, [applicationItem, id]);

    if (!applicationItem) {
        return (
            <Container>
                <Row>
                    <Col>
                        <h2>Loading...</h2>
                        <p>Fetching the exhibition details...</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            <Container fluid>
                <Row className='pt-5'>
                    <Col className='d-flex justify-content-center px-5 py-5'>
                        <img
                            src={applicationItem.img}
                            className='img-fluid w-100'
                            style={{ maxWidth: '90%', height: 'auto' }}
                            alt={applicationItem.title}
                        />
                    </Col>
                </Row>

                <Row className="pb-5">
                    <Col>
                        <Card className="rounded-5 cardshadow">
                            <h1 className='fw-bolder d-flex justify-content-center pt-5 px-5'>
                                {applicationItem.title}
                            </h1>
                            <Card.Body className='px-lg-5 pb-lg-5'>
                                <p dangerouslySetInnerHTML={{ __html: applicationItem.description }}></p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SolutionApplicationDetails;
