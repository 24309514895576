import React from 'react'
import Section1 from '../ComponentsPage/ProductDetail/Section1'
import Section2 from '../ComponentsPage/ProductDetail/Section2'
const ProductDetail = () => {
  return (
    <>
      <Section1/>
      {/* <Section2/> */}
    </>
  )
}

export default ProductDetail
