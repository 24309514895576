import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SolutionCardImg from '../../Assets/Images/SolutionOverview/SolutionCardImg.png';
import '../../Assets/Styles/Solution/SolutionOverview.css';
import { FaTimes } from "react-icons/fa";

const Section2 = () => {
    const [solutionAppCard, setSolutionAppCard] = useState([]);
    const [visible, setVisible] = useState(3);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const sectionRef = useRef(null); // Ref for the section

    useEffect(() => {
        const fetchSolutionCards = async () => {
            try {
                const response = await axios.get("ServiceDetail/get");
                setSolutionAppCard(response.data.responseData);
            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }
        };
        fetchSolutionCards();
    }, []);

    // Use Intersection Observer to trigger animation
    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it becomes visible
            }
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [sectionRef]);

    const truncateText = (text, limit) => {
        if (!text) return "No description available.";
        return text.length > limit ? text.substring(0, limit) + "..." : text;
    };

    const showMoreItems = () => {
        setVisible(solutionAppCard.length);
    };

    const showLessItems = () => {
        setVisible(3);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    return (
        <Container fluid ref={sectionRef} className={`fade-in ${isVisible ? 'visible' : ''}`}>
            <h1 className="fw-bold text-center textheading pt-5">
                We have already <span className='highlight'>implemented these solutions</span>
            </h1>
            <Row className="position-relative mb-5 px-lg-4">
                {solutionAppCard.slice(0, visible).map((member) => (
                    <Col xs={12} sm={6} md={6} lg={4} xl={4} className="pt-4" key={member.id}>
                        <Card className="rounded-5 h-100 cardshadows d-flex flex-column" style={{ height: '400px' }}> {/* Fixed height set here */}
                            <Card.Img
                                variant="top"
                                src={member.img || SolutionCardImg}
                                alt={member.name || "Implemented Image"}
                                className="pt-3 px-3 rounded-5"
                                onClick={() => handleImageClick(member.img || SolutionCardImg)}
                                style={{ height: '200px', objectFit: 'cover' }} // Ensure image is contained within fixed height
                            />
                            <Card.Body className="d-flex flex-column" style={{ flexGrow: 1 }}>
                                <h6 className="fw-bolder fs-4 text-uppercase mb-2" style={{ minHeight: '80px', maxHeight: '80px', overflow: 'hidden' }}>
                                    {member.title}
                                </h6>
                                <Card.Text className="text-justify text-capitalize lh-sm flex-grow-1 mb-0" style={{ minHeight: '120px', maxHeight: '120px', overflow: 'hidden' }}>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(member.description, 200) }} />
                                </Card.Text>
                            </Card.Body>
                            <div className="d-flex justify-content-end pb-3 pe-4 mt-auto">
                                <Link to={`/solutionapplicationdetails/${member.id}`} state={{ applicationItem: member }}>
                                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more">
                                        Learn more
                                    </Button>
                                </Link>
                            </div>

                        </Card>
                    </Col>
                ))}
            </Row>

            <div className="d-flex justify-content-center pt-3">
                {visible < solutionAppCard.length ? (
                    <Button onClick={showMoreItems} className="rounded-5 border-3 border-0 px-4 py-2 learn_more">
                        Load more
                    </Button>
                ) : (
                    <Button onClick={showLessItems} className="rounded-5 border-3 border-0 px-4 py-2 learn_more">
                        Show less
                    </Button>
                )}
            </div>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Body className="position-relative p-0">
                    <button
                        type="button"
                        className="position-absolute top-0 end-0 m-2"
                        onClick={handleClose}
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            fontSize: "1.5rem",
                            color: "#000",
                        }}
                    >
                        <FaTimes />
                    </button>
                    <img src={selectedImage} className="img-fluid w-100" alt="Product" />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Section2;
